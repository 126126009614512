var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "container staking" },
    [
      _c(
        "div",
        { staticClass: "staking__header-wrap" },
        [
          _c("market-place-header", { attrs: { urls: _vm.getPathUrls } }),
          _c(
            "ul",
            { staticClass: "staking__btns-list" },
            _vm._l(_vm.items, function (item, index) {
              return _c(
                "li",
                { key: index },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "staking__btn button--mint-white",
                      attrs: {
                        to: `/user_stakes/${item.link}`,
                        activeClass: "active",
                      },
                    },
                    [_vm._v(" " + _vm._s(item.text) + " ")]
                  ),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      ),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }