<template>
  <main class="container staking">
    <div class="staking__header-wrap">
      <market-place-header :urls="getPathUrls"/>
      <ul class="staking__btns-list">
        <li v-for="(item, index) in items" :key="index">
          <router-link
            :to="`/user_stakes/${item.link}`"
            class="staking__btn button--mint-white"
            activeClass="active"
          >
          {{ item.text }}
          </router-link>
        </li>
      </ul>
    </div>
    <router-view/>
  </main>
</template>

<script>
import MarketPlaceHeader from '@/components/market-place/header.vue';
import marketplaceMixin from '@/mixins/marketplaceMixin';

export default {
    name: 'stake',
    mixins: [marketplaceMixin],
    components: {
        MarketPlaceHeader,
    },
    computed: {
      getPathUrls() {
        return [
            {
                label: 'Staking',
                name: 'Staking',
            },
            {
                label: 'My stake',
                name: 'MyStake'
            },
        ]
      },
      items() {
        return [
            {
                text: 'STAKED',
                link: 'staked',
            },
            {
                text: 'UNSTAKE',
                link: 'unstake'
            },
            {
                text: 'HISTORY',
                link: 'history'
            },
        ]
      },
    },
}
</script>

<style lang="scss" scoped>
.staking {
  position: relative;
  width: 100%;
  padding-top: 45px;
  padding-bottom: 45px;

  &__header-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
  }

  &__btns-list {
    display: flex;
    gap: 25px;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 200px;
    min-height: 48px;
    padding: 10px 20px;

    font-family: "Tektur";
    font-size: 18px;
    font-weight: 500;
    line-height: 22.5px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }
}

@media screen and (max-width: ($bp_desktop - 1px)) {
  .staking {
    &__header-wrap {
      flex-direction: column;
      margin-bottom: 45px;
    }

    &__btns-list {
      justify-content: center;
      gap: 15px;
      margin-top: 30px;
    }

    &__btn {
      min-width: 130px;
      min-height: 40px;
      font-size: 16px;
    }
  }
}

@media screen and (max-width: ($bp_mb - 1px)) {
  .staking {
    &__btns-list {
      gap: 0;
      margin-top: 0;
    }

    &__btn {
      min-width: 96px;
      min-height: 36px;
      padding: 4px 4px;

      font-size: 14px;
      line-height: 17.5px;
    }
  }
}
</style>